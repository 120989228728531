<template>
	<div>
		<div style="margin-top: 10px;margin-left: 50px;">
			<el-button @click="getmass">监控质量查询</el-button>
		</div>
	</div>
</template>

<script>
	export default{
		name: "mass",
		data(){
			return{

			}
		},
		methods:{
			getmass(){
				this.axios.post("/videoquestion/getmass", (response) => {
					console.log(JSON.parse(response.obj))
				},{
					page: 9,
					pageSize: 10
				})
			}
		}
	}
</script>

<style>
</style>
